import React, { useState, useEffect, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './AuthorizationLetter.css';
import icon2Img from '../components/images/se.png';
import compsign from '../components/images/compsign.png';
import QRCode from 'react-qr-code';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 

const AuthorizationLetter = () => {

    const [showNewButton, setShowNewButton] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [referenceNumber, setReferenceNumber] = useState('');
    const [parsedInfo, setParsedInfo] = useState({});



    const [additionalInfo, setAdditionalInfo] = useState({});
    const [additionalInfo2, setAdditionalInfo2] = useState({});
    const [error, setError] = useState(null);
    const [serviceDetails, setServiceDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    const getTitleDescription = (title) => t(`Obstetrics_and_Gynecology.${title}`);
    const { t, i18n } = useTranslation("global");
    const location = useLocation();
    const getDirection = () => (i18n.language === "ar" ? "rtl" : "ltr");
    const language = i18n.language === "ar" ? "Ar" : "En";








    const conditions = [
        { id: 1, text: "هذا العرض صالح للاستخدام مرة واحدة و لحامله ولا يمكن التنازل عنه إلا بعد موافقة المنصة." },
        { id: 2, text: "يخضع هذا العرض لشروط التجارة الإلكترونية الصادر عن وزارة التجارة بالمملكة العربية السعودية." },
        { id: 3, text: "يخضع هذا العرض للشروط والأحكام العامة لمنصة لهاولك الطبية و المعلن عنها بالموقع الإلكتروني ومواقع التواصل الإجتماعي." },
        { id: 4, text: "بحسب القوانين السارية فإن المواطنين معفيين من الضريبة المضافة للخدمات الطبية و الأسعار المعروضة لا تشمل الضريبة" }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const refNumber = params.get('referenceNumber');

        if (refNumber) {
            setReferenceNumber(refNumber);
            const parsedData = parseReferenceNumber(refNumber);
            if (parsedData?.serviceId && parsedData?.hospitalId) {
                fetchAdditionalInfoByIdN(parsedData.serviceId, parsedData.hospitalId);
            }

            if (!additionalInfo.date) {
                fetchAdditionalInfoByReference(refNumber);
            }
        }
    }, [location, additionalInfo.date]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const refNumber = params.get('referenceNumber');

        if (refNumber) {
            setReferenceNumber(refNumber);
            const parsedData = parseReferenceNumber(refNumber);

            if (parsedData?.serviceId && parsedData?.hospitalId) {
                fetchAdditionalInfoByIdN(parsedData.serviceId, parsedData.hospitalId);
            }
        }
    }, [location]);




    const generatePDF = async () => {
        const mainContent = document.getElementById('invoice');
        const secondPageContent = document.getElementById('secpage');
    
        try {
            setLoadingPdf(true);
    
            const fixedWidth = 800;
    
            // Render the first page
            const mainCanvas = await html2canvas(mainContent, {
                scale: 1,
                useCORS: true,
                allowTaint: false,
                width: fixedWidth,
                height: mainContent.scrollHeight,
                windowWidth: fixedWidth,
            });
    
            const pdfWidth = fixedWidth * 0.75; // Adjust for A4 width
            const pdfHeight = (mainCanvas.height / mainCanvas.width) * pdfWidth;
    
            const pdf = new jsPDF('portrait', 'pt', [pdfWidth, pdfHeight]);
            const mainImgData = mainCanvas.toDataURL('image/png', 1.0);
            pdf.addImage(mainImgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    
            // Add a new page and render the second page
            pdf.addPage();
            const secondCanvas = await html2canvas(secondPageContent, {
                scale: 1,
                useCORS: true,
                allowTaint: false,
                width: fixedWidth,
                height: secondPageContent.scrollHeight,
                windowWidth: fixedWidth,
            });
    
            const secondPageHeight = (secondCanvas.height / secondCanvas.width) * pdfWidth;
            const secondImgData = secondCanvas.toDataURL('image/png', 1.0);
            pdf.addImage(secondImgData, 'PNG', 0, 0, pdfWidth, secondPageHeight);
    
            // Generate the PDF Blob
            const pdfBlob = pdf.output('blob');
            const pdfBlobUrl = URL.createObjectURL(pdfBlob);
    
            const reference_number = referenceNumber;
            const pdfFileName = `${reference_number}_خطاب_تعميد_يقدم_للمشفى.pdf`;
    
            // Create a temporary download link for the PDF
            const downloadLink = document.createElement('a');
            downloadLink.href = pdfBlobUrl;
            downloadLink.download = pdfFileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
    
            // Upload the PDF Blob to the server
            const uploadPdfUrl = process.env.REACT_APP_UPLOAD_PDF_URL;
            const pdfFormData = new FormData();
            pdfFormData.append('reference_number', reference_number);
            pdfFormData.append('pdf_file', pdfBlob, pdfFileName);
    
            const pdfResponse = await fetch(uploadPdfUrl, {
                method: 'POST',
                body: pdfFormData,
            });
    
            const pdfResult = await pdfResponse.json();
            if (!pdfResult.success) {
                throw new Error(`Failed to upload PDF: ${pdfResult.message}`);
            }
    
            // Clean up the Blob URL
            URL.revokeObjectURL(pdfBlobUrl);
    
            setShowNewButton(true);
        } catch (error) {
            console.error('Error during PDF generation or upload:', error);
        } finally {
            setLoadingPdf(false);
        }
    };
    





    const parseReferenceNumber = (refNumber) => {
        const parts = refNumber.split('|').filter(Boolean);
        if (parts.length === 3) {
            let [serviceIdWithHospital, nationality, randomNumber] = parts;

            const [serviceId, hospitalId] = serviceIdWithHospital.split('_');

            setParsedInfo({
                type: 'General Service',
                serviceId,
                hospitalId,
                nationality: nationality === 'SA' ? 'Saudi' : 'Non-Saudi',
                randomNumber,
            });



            fetchAdditionalInfoByIdN(serviceId, hospitalId);

        } else if (parts.length === 4) {
            // Pregnancy Service format: |month|service_id|nationality|random_number
            const [month, serviceId, nationality, randomNumber] = parts;

            setParsedInfo({
                type: 'Pregnancy Service',
                month,
                serviceId,
                nationality: nationality === 'SA' ? 'Saudi' : 'Non-Saudi',
                randomNumber,
            });



            // Load service details from translation file based on language and serviceId
            import(`../translation/${language}/Call.json`)
                .then((module) => {
                    const serviceData = module.default.find(service => service.id === serviceId);
                    if (serviceData) {
                        setServiceDetails(serviceData);
                    } else {
                        setError('Service details not found.');
                    }
                })
                .catch((err) => {
                    setError('Failed to load service details.');
                    // console.error(err);
                });

        } else {
            setError('Invalid reference number format.');
        }
    };

    // for pregency
    const fetchAdditionalInfoByReference = async (refNumber) => {
        try {
            const baseUrl = process.env.REACT_APP_AUTHORIZATION_LETTER_URL;
            const url = `${baseUrl}?referenceNumber=${refNumber}`;


            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
            });

            const data = await response.json();

            if (data.error) {
                // console.error("Error fetching data by reference number:", data.error);
                setError(data.error);
            } else {
                // console.log("Fetched data by reference number:", data);
                setAdditionalInfo((prevInfo) => ({
                    ...prevInfo,
                    ...data,
                }));
            }
        } catch (error) {
            // console.error("Failed to fetch additional info by reference number:", error);
            setError('Failed to fetch additional information by reference number.');
        }
    };



    const fetchAdditionalInfoByIdN = async (serviceId, hospitalId) => {
        try {
            const baseUrl = process.env.REACT_APP_AUTHORIZATION_LETTER_IDN_URL;
            const url = `${baseUrl}?serviceId=${serviceId}&hospitalId=${hospitalId}`;


            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }

            const dataIDN = await response.json();

            const parsedServices = dataIDN.services ? JSON.parse(dataIDN.services) : null;
            const parsedSpecialConditions = dataIDN.special_conditions ? JSON.parse(dataIDN.special_conditions) : null;
            const parsedIncludedServices = dataIDN.included_services ? JSON.parse(dataIDN.included_services) : null;




            setParsedInfo((prevInfo) => ({
                ...prevInfo,
                services: parsedServices,
                special_conditions: parsedSpecialConditions,
                included_services: parsedIncludedServices,
            }));

            return dataIDN;

        } catch (error) {
            // console.error("Failed to fetch additional info:", error);
            setError("Failed to retrieve additional information. Please try again.");
            return null;
        }
    };












    return (
        <div >
            <br />
            <br />
            <div className="auth-container" style={{ direction: getDirection() }}>

                <div
                    style={{
                        textAlign: 'center',
                        direction: getDirection(),
                        backgroundColor: '#ffffff',
                    }}
                    className="invoiceA"
                    id="invoice"
                >                <header className="invoice-header" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>


                        <div style={{ textAlign: 'right' }}>
                            <p className="headerpdfT">منصة لها ولك الطبية </p>
                            <p className="headerpdf">رقـــــــــم معروف 214657</p>
                            <p className="headerpdf">عـــــلامة تجارية 1443017765</p>
                            <p className="headerpdf"> السجل التجاري 4030402711</p>
                            <p className="headerpdf">الرقم الضريبي 300095977700003</p>
                        </div>
                        <img
                            src="images/loba2.png"
                            alt="Description of the image"
                            style={{ height: 'auto', width: '50px' }}
                        />
                        <div style={{ textAlign: 'left' }}>
                            <p className="headerpdfT">LahaLak medical platform</p>
                            <p className="headerpdf">Maroof no :214657</p>
                            <p className="headerpdf">T.M 1443017765</p>
                            <p className="headerpdf">C.R. 4030402711</p>
                            <p className="headerpdf">Tax reg 300095977700003 </p>

                        </div>
                    </header>
                    {referenceNumber && (
                        <div className="user-details" dir={getDirection(i18n.language)}>
                            <h1>{t("pdfuser.letter")}</h1>
                            <div className="palambido" dir={getDirection(i18n.language)}>
                                <div className="samirainfoItem">
                                    <p className="label">{t("pdfuser.Reference")}</p>
                                    <p className="value">{referenceNumber}</p>
                                </div>
                                <div className="samirainfoItem">
                                    <p className="label">{t("pdfuser.Date")}</p>
                                    <p className="value">{additionalInfo.date}
                                    </p>
                                </div>
                            </div>

                        </div>

                    )}
                    {error ? (
                        <p style={{ color: 'red' }}>{error}</p>
                    ) : (
                        <>

                            <Khadamethamel
                                parsedInfo={parsedInfo}
                                additionalInfo={additionalInfo}
                                serviceDetails={serviceDetails}
                                t={t}
                                getTitleDescription={getTitleDescription}
                                i18n={i18n}
                                // birthday={birthday}
                                // setBirthday={setBirthday}
                                // idNumber={idNumber}
                                // setIdNumber={setIdNumber}
                                getDirection={getDirection} // Pass getDirection here

                            />

                            {/* khadamet_Amme */}


                            <KhadametAmme
                                additionalInfo={additionalInfo}
                                setAdditionalInfo={setAdditionalInfo}
                                t={t}
                                i18n={i18n}
                                icon2Img={icon2Img}
                                conditions={conditions}
                                refNumber={referenceNumber}
                                // birthday={birthday} // Pass birthday
                                // setBirthday={setBirthday} // Pass setBirthday
                                // idNumber={idNumber} // Pass idNumber
                                // setIdNumber={setIdNumber} // Pass setIdNumber
                                parsedInfo={parsedInfo}
                                serviceDetails={serviceDetails}
                                getTitleDescription={getTitleDescription}
                                getDirection={getDirection} // Pass getDirection here

                            />

                        </>
                    )}
                </div>
                <div
                    id="secpage"
                    style={{
                        textAlign: 'right',
                        direction: 'rtl',
                        marginTop: '20px',
                        padding: '20px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#f9f9f9',
                        fontFamily: 'Arial, sans-serif',
                    }}
                >
                    <h2 style={{ fontSize: '1.5em', color: '#333', marginBottom: '10px' }}>معلومات الاتصال</h2>
                    <p style={{ fontSize: '1em', color: '#666', marginBottom: '20px' }}>للاستفسارات أو المساعدة، يرجى التواصل معنا:</p>

                    <div style={{ lineHeight: '1.8', color: '#444' }}>
                        <p style={{ margin: '5px 0' }}>مواقعنا</p>
                        <p style={{ margin: '5px 0' }}>جدة: عمارة نورا - الدور الأول، شارع فلسطين، 6286، حي الحمراء 3738, Jeddah 23323.</p>
                        <p style={{ margin: '5px 0' }}>الرياض: شارع الضباب 15366، المربعة - قاعة 4.</p>
                        <p style={{ margin: '5px 0' }}>
                            <a href="tel:920014308" style={{ color: 'inherit', textDecoration: 'none' }}>920014308</a>
                        </p>
                        <p style={{ margin: '5px 0' }}>
                            <a href="tel:+966571605516" style={{ color: 'inherit', textDecoration: 'none' }}>+966 57 160 5516</a>
                        </p>

                        <p style={{ margin: '5px 0' }}>
                            البريد الإلكتروني: <a href="mailto:Info@lahalak.sa" style={{ color: '#007bff', textDecoration: 'none' }}>Info@lahalak.sa</a>
                        </p>
                    </div>

                    <div className="footerLettre">
                        <FooterLettre referenceNumber={referenceNumber} />
                    </div>


                </div>








































                <div>



                    <button
                        onClick={() => {
                            setLoadingPdf(true);
                            generatePDF();
                        }}
                        className="button-downloadF buttonwhite"
                    >
                        {loadingPdf ? (
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <i className="fa fa-spinner fa-spin"></i>
                                {t("pdfuser.generatingQuotation")}
                            </span>
                        ) : (
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {t("pdfuser.saveQuot")}
                                <i className="fa fa-download" style={{ marginLeft: '8px' }}></i>
                            </span>)}
                    </button>


                </div>






            </div>
        </div>
    );
};


export default AuthorizationLetter;















function Khadamethamel({ getDirection,
    parsedInfo,
    additionalInfo,
    serviceDetails,
    t,
    getTitleDescription,
    i18n,
}) {
    return (
        <div>
            {parsedInfo.type === 'Pregnancy Service' && (
                <div className="hamelkhadamet">

                    {additionalInfo.customer_number && (
                        <div >
                      <div className="whiteB">
    <p style={{ textAlign: "start", margin: 0, padding: 0 }}>
        {`${t("pdfuser.DearHospital")} ${getTitleDescription(serviceDetails?.Title) || "N/A"} - ${
            getTitleDescription(serviceDetails?.Location) || "N/A"
        } - ${getTitleDescription(serviceDetails?.Branch) || "N/A"} ${t("pdfuser.Respectful")}`}
    </p>
    <p style={{ textAlign: "start", marginTop: "10px", padding: 0 }}>
        {`${t("pdfuser.RequestService")} "${additionalInfo.name || "N/A"}", ${t("pdfuser.ServiceDetails")}`}
    </p>
</div>


                            <br />
                            <div className='BluedivHC' dir={getDirection(i18n.language)}>
                                <p className='titlecsollapseeWak'>{t("pdfuser.data")}</p>
                            </div>
                            <div className='det_graut'>
                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.name")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.name}</span>
                                </div>



                                {/* <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.Total")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.total}&nbsp; {t("Services.Pur6")}</span>
                                </div> */}

                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.phone")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.customer_number}</span>
                                </div>
                                <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.nationality")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>
                                        {t(`Obstetrics_and_Gynecology.${parsedInfo.nationality}`)}
                                    </span>
                                </div>

                                <div className='grifcellule ' >
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.Service")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.service}</span>
                                </div>
                                {/* <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("pdfuser.Service")}:</p>
                                    <span className='sostext' style={{ display: 'inline' }}>{additionalInfo.encrypted}</span>
                                </div> */}
                                {/* <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("UserInfoPage.dob")}</p>
                                    <input id="6"
                                        type="date"
                                        className='sostext'
                                        value={birthday}
                                        onChange={(e) => setBirthday(e.target.value)} // Update birthday state
                                        style={{ display: 'inline' }}
                                    />
                                </div> */}
                                <div className="responsive-divider"></div>

                                {/* ID Number Input */}
                                {/* <div className='grifcellule'>
                                    <p className='sostitel' style={{ display: 'inline' }}>{t("UserInfoPage.ID")}:</p>
                                    <input
                                        type="text"
                                        className='sostext'
                                        value={idNumber}
                                        onChange={(e) => setIdNumber(e.target.value)} // Update idNumber state
                                        style={{ display: 'inline' }}
                                        placeholder={t("UserInfoPage.enterID")}
                                    />
                                </div> */}
                            </div>

                        </div>
                    )}


                    {serviceDetails ? (
                        <div className="service-details">

                            <div className="fetched-data">
                                <br />

                                <div className='titlecollapseeB'>
                                    <div className='BluedivHC'>
                                        <p className='titlecsollapseeWak'>{t("Details.includedServices")}</p>
                                    </div>

                                </div>
                                <div className='contcoffee'>

                                    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        {serviceDetails.IncludedServices && serviceDetails.IncludedServices.map((service, index) => (
                                            <div key={index}>
                                                <br />
                                                <div className='titlecollapseeB'>
                                                    <div className='Bluediv'>
                                                        <p className='titlecollapseeWak'>{service.title}</p>
                                                    </div>
                                                </div>
                                                <br />
                                                <ul>
                                                    {service.description.map((desc, idx) => (
                                                        <li key={idx} className="totire">
                                                            <img src={icon2Img} alt="Icon" />
                                                            <span>{desc}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>

                                    {/* <div className='Bluediv'>
                                        <p className='titlecollapseeWak'>{t("Details.addedValues")}</p>
                                    </div>
                                    <br />
                                    <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        {serviceDetails.AddedValues && serviceDetails.AddedValues.map((value, index) => (
                                            <div className='titlecollapseeB' key={index}>
                                                <ul>
                                                    <li className="totire">
                                                        <img src={icon2Img} alt="Icon" />
                                                        <span>{value}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        ))}
                                    </div> */}
                                    <br />

                                    <div className='titlecollapseeB'>
                                        <div className='BluedivHC'>
                                            <p className='titlecsollapseeWak'>{t("Details.specialConditions")}</p>
                                        </div>

                                        <br />
                                    </div>
                                    <br />
                                    <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        <ul style={{ padding: 0 }}>
                                            {serviceDetails.SpecialConditions && serviceDetails.SpecialConditions.map((condition, index) => (
                                                <li className="safamak" key={index}>
                                                    <span>{condition}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <br />

                                    <div className='titlecollapseeB'>


                                        <div className='BluedivHC'>
                                            <p className='titlecsollapseeWak'>{t("Details.generalConditions")}</p>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                        <ul style={{ padding: 0 }}>
                                            {serviceDetails.GeneralConditions && serviceDetails.GeneralConditions.map((condition, index) => (
                                                <li className="safamak" key={index}>
                                                    <span>{condition}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>Loading service details...</p>
                    )}


                </div>
            )}
        </div>
    )
}




























function KhadametAmme({
    parsedInfo,
    additionalInfo,
    setAdditionalInfo,
    t,
    i18n,
    icon2Img,
    conditions,
    refNumber,
    getDirection,
}) {
    const [additionalInfo2, setAdditionalInfo2] = useState({});
    const [error, setError] = useState(null); // Track errors
    const [loading, setLoading] = useState(true); // Track loading state

    const fetchAdditionalInfo = async () => {
        try {
            const baseUrl = process.env.REACT_APP_AUTHORIZATION_LETTER_GENERAL_URL;
            const url = `${baseUrl}?referenceNumber=${encodeURIComponent(refNumber)}`;
            console.log("Fetching data from:", url);

            const response = await fetch(url, {
                method: "GET",
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error(`Failed to fetch: ${response.status} - ${response.statusText}`);
            }
            const data = await response.json();
            console.log("Fetched data:", data);
            setAdditionalInfo2(data);
        } catch (error) {
            console.error("Error fetching additional information:", error.message);
            setError(t("Error fetching additional information. Please try again later."));
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (!additionalInfo?.special_conditions && refNumber) {
            fetchAdditionalInfo();
        }
    }, [additionalInfo, refNumber]);

    return (
        <>
            {parsedInfo.type === "General Service" && (
                <div className="khadamet_Amme">
                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p style={{ color: "red" }}>Error: {error}</p>
                    ) : (
                        additionalInfo.customer_number || additionalInfo2.customer_number ? (
                            <div>
                                <div className="whiteB">
                                    <p style={{ textAlign: "start", margin: 0, padding: 0 }}>
                                        {`${t("pdfuser.DearHospital")} ${additionalInfo.hospitalName || additionalInfo2.hospitalName || "N/A"
                                            } - ${additionalInfo.location || additionalInfo2.location || "N/A"
                                            } - ${additionalInfo.subtitle || additionalInfo2.subtitle || "N/A"
                                            } ${t("pdfuser.Respectful")}`}
                                    </p>
                                    <p style={{ textAlign: "start", marginTop: "10px", padding: 0 }}>
                                        {t("pdfuser.RequestService")} "{additionalInfo.name || additionalInfo2.name}", {t("pdfuser.ServiceDetails")}.
                                    </p>
                                </div>


                                <br />

                                <div className="BluedivHC" dir={getDirection(i18n.language)}>
                                    <p className="titlecsollapseeWak">{t("pdfuser.data")}</p>
                                </div>
                                <div className="det_graut">
                                    <div className="grifcellule">
                                        <p className="sostitel" style={{ display: "inline" }}>
                                            {t("pdfuser.phone")}:
                                        </p>
                                        <span className="sostext" style={{ display: "inline" }}>
                                            {additionalInfo.customer_number || additionalInfo2.customer_number}
                                        </span>
                                    </div>
                                    <div className="grifcellule">
                                        <p className="sostitel" style={{ display: "inline" }}>
                                            {t("pdfuser.name")}:
                                        </p>
                                        <span className="sostext" style={{ display: "inline" }}>
                                            {additionalInfo.name || additionalInfo2.name}
                                        </span>
                                    </div>
                                    <div className="grifcellule">
                                        <p className="sostitel" style={{ display: "inline" }}>
                                            {t("pdfuser.nationality")}:
                                        </p>
                                        <span className="sostext" style={{ display: "inline" }}>
                                            {t(`Obstetrics_and_Gynecology.${parsedInfo.nationality}`)}
                                        </span>
                                    </div>
                                    <div className="grifcellule">
                                        <p className="sostitel" style={{ display: "inline" }}>
                                            {t("pdfuser.Service")}:
                                        </p>
                                        <span className="sostext" style={{ display: "inline" }}>
                                            {additionalInfo.service || additionalInfo2.service}
                                        </span>
                                    </div>
                                </div>

                                <div className="contcoffee">
                                    <div className="fetched-data">
                                        <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                                            <div className="titlecollapseeB">
                                                <div className="BluedivHC">
                                                    <p className="titlecsollapseeWak">
                                                        {t("Details.includedServices")}
                                                    </p>
                                                </div>
                                                <br />
                                            </div>
                                            <ul>
                                                {Array.isArray(parsedInfo.included_services?.content) &&
                                                    parsedInfo.included_services.content.length > 0 ? (
                                                    parsedInfo.included_services.content.map((item, index) => (
                                                        <li key={`included-service-${index}`} className="totire">
                                                            <img src={icon2Img} alt="Icon" />
                                                            <span>{item.trim()}</span>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <li>No included services available.</li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <br />
                                    <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
                                        <div className="titlecollapseeB">
                                            <div className="BluedivHC">
                                                <p className="titlecsollapseeWak">{t("Details.specialConditions")}</p>
                                            </div>
                                            <br />
                                        </div>
                                        <ul>
                                            {Array.isArray(parsedInfo.special_conditions?.content) &&
                                                parsedInfo.special_conditions.content.length > 0 ? (
                                                parsedInfo.special_conditions.content.map((item, index) => (
                                                    <li key={`included-service-${index}`} className="totire">
                                                        <img src={icon2Img} alt="Icon" />
                                                        <span>{item.trim()}</span>
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No conditions available.</li>
                                            )}
                                        </ul>
                                    </div>
                                    <br />
                                    <div className="titlecollapseeB">
                                        <div className="BluedivHC">
                                            <p className="titlecsollapseeWak">{t("Details.generalConditions")}</p>
                                        </div>
                                        <br />
                                    </div>
                                    <div
                                        className={`${i18n.language === "ar" ? "rtl" : "ltr"
                                            } titlecollapseeB`}
                                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                    >
                                        <ul style={{ padding: 0 }}>
                                            {conditions.map((condition) => (
                                                <li key={condition.id} className="safamak">
                                                    {condition.text}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p>No customer number available.</p>
                        )
                    )}
                </div>
            )}
        </>
    );
}



const FooterLettre = ({ referenceNumber }) => {
    const qrCodeUrl = `https://lahalak.sa/Authorization_letter?referenceNumber=${referenceNumber}`;

    return (
        <div className="footerLettre">
            {/* <img src={compsign} alt="Icon" className="signature-iconsa" /> */}
            <div style={{ background: 'white', padding: '16px' }}>
                <QRCode
                    value={qrCodeUrl}
                    size={150}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="H"
                />
            </div>
            <p className='ferkaSebaa'>هذا المستند صادر من خلال منصة لها لك الطبيه ولا يتطلب اي توقيع فقد تم انشاؤه والمصادقه عليه الكترونيا ويمكنك الاستفسار عن المستند من خلال مسح "رمز الاستجابة السريعة (QR Code)"</p>
        </div>
    );
};

