import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import "./SendMessage.css";

const SendMessage = () => {
  const { t, i18n } = useTranslation("global");
  const location = useLocation();
  const { MessagTitle: initialMessagTitle } = location.state || {};

  const [name, setName] = useState("");
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [message, setMessage] = useState("");
  const [MessagTitle, setMessagTitle] = useState(initialMessagTitle || "");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    console.log("Form submission started.");
  
    // Validation checks
    if (!name || !emailOrPhone || !MessagTitle || !message) {
      console.error("Validation error: All fields are required.");
      setError(t("faq.allFieldsRequired"));
      return;
    }
  
    const isPhone = /^05\d{8}$/.test(emailOrPhone);
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailOrPhone);
  
    if (!isPhone && !isEmail) {
      console.error("Validation error: Invalid email or phone format.");
      setError(t("faq.invalidEmailOrPhone"));
      return;
    }
  
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      console.log(`Fetching CSRF cookie from: ${API_BASE_URL}/sanctum/csrf-cookie`);
  
      // Fetch CSRF cookie
      const csrfResponse = await fetch(`${API_BASE_URL}/sanctum/csrf-cookie`, {
        method: "GET",
        credentials: "include",
      });
  
      if (!csrfResponse.ok) {
        console.error("Failed to fetch CSRF cookie:", csrfResponse.status, csrfResponse.statusText);
        throw new Error("CSRF cookie fetch failed.");
      }
  
      console.log("CSRF cookie fetched successfully.");
  
      console.log(`Sending message to: ${API_BASE_URL}/api/messages`);
  
      // Submit the form data
      const response = await fetch(`${API_BASE_URL}/api/messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          emailOrPhone,
          message,
          MessagTitle,
        }),
        credentials: "include",
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response from server:", errorData);
  
        if (errorData.errors) {
          setError(Object.values(errorData.errors).join(" "));
        } else {
          setError(t("faq.submitFailed"));
        }
  
        throw new Error("Message submission failed.");
      }
  
      console.log("Message submitted successfully.");
  
      // Reset form and show success message
      setSuccess(true);
      setError("");
      setName("");
      setEmailOrPhone("");
      setMessagTitle("");
      setMessage("");
    } catch (err) {
      console.error("Error submitting the message:", err.message);
      setError(t("faq.serverError"));
    }
  };
  


  const getDirection = () => (i18n.language === "ar" ? "rtl" : "ltr");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="establishment-services-assistance-container">
      <div className="ayn1" dir={getDirection()}>
        <h1 className="ayn2">{t("faq.sendMessage")}</h1>
        {error && <p className="ayn3">{error}</p>}
        {success && (
          <div className="ayn11">
            <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
            <p className="ayn4">{t("faq.submitSuccess")}</p>
          </div>
        )}
        <form className="ayn5" onSubmit={handleSubmit}>
          <div className="ayn6">
            <label className="ayn7" htmlFor="name">{t("faq.name")}</label>
            <input
              id="name"
              type="text"
              className="ayn8"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="ayn6">
            <label className="ayn7" htmlFor="emailOrPhone">{t("faq.emailOrPhone")}</label>
            <input
              id="emailOrPhone"
              type="text"
              className="ayn8"
              value={emailOrPhone}
              onChange={(e) => setEmailOrPhone(e.target.value)}
              required
            />
          </div>
          <div className="ayn6">
            <label className="ayn7" htmlFor="MessagTitle">{t("faq.messageTitle")}</label>
            <input
              id="MessagTitle"
              type="text"
              className="ayn8"
              value={MessagTitle}
              onChange={(e) => setMessagTitle(e.target.value)}
              readOnly={Boolean(initialMessagTitle)} // Prevent editing if passed from state
              required
            />
          </div>
          <div className="ayn6">
            <label className="ayn7" htmlFor="message">{t("faq.message")}</label>
            <textarea
              id="message"
              className="ayn9"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="ayn10">{t("faq.send1")}</button>
        </form>
      </div>
    </div>
  );
};

export default SendMessage;
