import React, { useEffect } from 'react';
import './Slideshow.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; 
import logo from '../components/images/loba2.png';
// images/payment_option/mc_footerlogo.png';
const Slideshow = () => {
  const { t, i18n } = useTranslation("global");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const cardsData = [
    {
      img: logo,
      title: t("Slideshow.title1"),
      text: t("Slideshow.text1"),
    },
    {
      img: logo,
      title: t("Slideshow.title2"),
      text: t("Slideshow.text2"),
    },
    {
      img: logo,
      title: t("Slideshow.title3"),
      text: t("Slideshow.text3"),
    },
    {
      img: logo,
      title: t("Slideshow.title4"),
      text: t("Slideshow.text4"),
    },
  ];

  return (
    <div className='why' style={{ direction: getDirection() }}>
      <br />
      <br />
      <br />
      <br />
      <p className='warda'>{t("Slideshow.title")}</p>
      <br />

      <div className="cardss-container" >
        {cardsData.map((card, index) => (
          <div key={index} className="cards">
            <div className="cards-text">
              <div className="cards-img">
                <img src={card.img} alt="icon" />
              </div>
              <br />
              <div className="cards-title">{card.title}</div>
              <div className="cards-body">{card.text}</div>
            </div>
          </div>
        ))}
      </div>
      <br />
      <div className='centre'>
        <div >
          <br />
          <p className="subTitleContfak11"
          >
            {t("Slideshow.titleQuestionD")}
          </p>
          <br />
          <div >
            <Link to="/SendMessage" state={{ MessagTitle: "طلب معين" }}>
              <button
                className='blueNewTkhile cleopaetra1011'
              >
                {t("Slideshow.txtQuestionD1")}
              </button> 
            </Link>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Slideshow;
