import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Payment.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';

const Payment = () => { 
    const { t, i18n } = useTranslation("global");

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const location = useLocation(); 
    const { name, phone, amount, reference_number, email } = location.state || {};

    // Log the state to debug
    console.log('Payment Component State:', { reference_number });
    console.log('Email:', email);
    console.log({amount});

    const [error, setError] = useState('');

    useEffect(() => {
        if (!name || !phone || !amount || !email || !reference_number) {
            setError(t("Payment.missingInfo"));
        }
    }, [name, phone, amount, email, reference_number, t]);

    const handlePaymentNow = async (e) => {
        e.preventDefault();

        console.log("Amount:", amount);
        console.log("Email:", email);
        console.log("Phone Number:", phone);
        console.log("Reference Number:", reference_number);

        if (!amount || !email || !phone || !reference_number) {
            setError(t("Payment.missingInfo"));
            return;
        }

        const formData = new FormData();
        formData.append('amount', amount);
        formData.append('payment_option', 'NORMAL'); // Fixed to NORMAL
        formData.append('email', email);
        formData.append('phone_number', phone);
        formData.append('reference_number', reference_number);

        try {
            const processPaymentUrl = process.env.REACT_APP_PROCESS_PAYMENT_URL;

            const response = await fetch(processPaymentUrl, {
                method: 'POST',
                body: formData,
            });

            const result = await response.text();
            document.open();
            document.write(result);
            document.close();
        } catch (error) {
            setError(t("Payment.errorProcessing"));
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="payment-container" style={{ direction: getDirection() }}>
            <h1 className="payment-for">{t("Payment.paymentForm")}</h1>
            {error && <p className="payment-error-message">{error}</p>}
            <div className="card-wrapper">
                <div className="card card-background">
                    <form onSubmit={handlePaymentNow} className="payment-form">
                        <div className="form-group">
                            <label>{t("Payment.amountSAR")}</label>
                            <p className="form-value">{(amount).toFixed(2)}</p>
                        </div>
                        <div className="form-group">
                            <label>{t("Payment.email")}:</label>
                            <p className="form-value">{email}</p>
                        </div>
                        <div className="form-group">
                            <label>{t("Payment.phoneNumber")}:</label>
                            <p className="form-value">{phone}</p>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="submit-button">
                                {t("Payment.payNow")}
                            </button>
                        </div>
                    </form>
                </div>
                <div className="card card-white">
                    <p>{t("Payment.normalPaymentDescription")}</p>
                </div>
            </div>
        </div>
    );
};

export default Payment;

















































