

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import icon2Img from '../components/images/se.png';
import loba2 from './images/loba2.png';

import './Pdfuser.css';

function Pdfuser() {
    const { t, i18n } = useTranslation("global");
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const location = useLocation();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const {
        id = null,
        name = null,
        phone = null,
        total = 0,
        reference_number = null,
        nationality = null,
        Service = null,
        date = null,
        hospital = null,
        roomType = null,
        email = null
    } = location.state || {};
    const [discountCode, setDiscountCode] = useState(''); // For discount code input
    const [discountAmount, setDiscountAmount] = useState(0); // For discount amount
    const [discountError, setDiscountError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const parsedTotal = typeof total === 'string' ? Number(total) : total;

    const calculatePrices = (parsedTotal, nationality) => {
        const servicePrice = parsedTotal * 0.75; // 75% of the total price
        const contractPrice = parsedTotal * 0.25; // 25% of the total price
        const vatServicePrice = nationality === "Saudi" ? 0 : servicePrice * 0.15; // VAT for service
        const vatContractPrice = contractPrice * 0.15; // VAT for administrative fees
        const vatContractPriceT = vatContractPrice + contractPrice; // Total contract price with VAT
        const totalWithVat = parsedTotal + vatServicePrice + vatContractPrice; // Total price with VAT

        const finalAmount = discountAmount > 0 ? totalWithVat - discountAmount : totalWithVat;

        console.log("Final Amount to Pay:", finalAmount.toFixed(2));

        return {
            servicePrice,
            contractPrice,
            vatServicePrice,
            vatContractPrice,
            vatContractPriceT,
            totalWithVat,
            finalAmount,
        };
    };



    const {
        servicePrice,
        contractPrice,
        vatServicePrice,
        vatContractPrice,
        vatContractPriceT,
        totalWithVat,
        finalAmount,
    } = calculatePrices(parsedTotal, nationality);


    const [isButtonVisible, setIsButtonVisible] = useState(true);

    const [agreed, setAgreed] = useState(false);

    const [types, setTypes] = useState([]);
    const [error, setError] = useState(null);
    const [Branch, setBranch] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showNewButton, setShowNewButton] = useState(false);
    useEffect(() => {
        if (showNewButton) {
            console.log("New button should be displayed now.");
        }
    }, [showNewButton]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const language = i18n.language === "ar" ? "Ar" : "En";
                const dataModule = await import(`../translation/${language}/Call.json`);
                const calls = dataModule.default;
                const typeData = calls.filter(call => call.id === String(id));
                if (typeData.length === 0) {
                    throw new Error('No matching data found for the provided ID');
                }
                setTypes(typeData[0]);

                const matchedData = typeData[0];
                if (matchedData && matchedData.Branch) {
                    setBranch(matchedData.Branch);
                    console.log("Branch set to:", matchedData.Branch);
                } else {
                    setBranch(null);
                }

                setError(null);
            } catch (err) {
                console.error("Error fetching data:", err.message);
                setError(`Error: ${err.message}`);
                setBranch(null);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id, i18n.language]);

    useEffect(() => {
    }, [location.state]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handlePaymentNow = async (e) => {
        e.preventDefault();

        // Validate required fields
        if (!email || !phone || !reference_number) {
            setError(t("Payment.missingInfo"));
            return;
        }

        const {
            servicePrice,
            contractPrice,
            vatServicePrice,
            vatContractPrice,
            totalWithVat,
            finalAmount,
        } = calculatePrices(parsedTotal, nationality);

        if (finalAmount <= 0) {
            setError(t("Payment.invalidPaymentAmount"));
            return;
        }

        const formData = new FormData();
        formData.append('amount', finalAmount.toFixed(2));
        formData.append('payment_option', 'NORMAL');
        formData.append('email', email);
        formData.append('phone_number', phone);
        formData.append('reference_number', reference_number);

        try {
            const processPaymentUrl = process.env.REACT_APP_PROCESS_PAYMENT_URL;
            const response = await fetch(processPaymentUrl, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(t("Payment.errorProcessing"));
            }

            const result = await response.text();
            document.open();
            document.write(result);
            document.close();
        } catch (error) {
            console.error("Error processing payment:", error);
            setError(t("Payment.errorProcessing"));
        }
    };

    const handleValidateDiscountCode = async () => {
        if (!discountCode) {
            setDiscountError(t("Payment.discountCodeEmpty"));
            setDiscountAmount(0);
            return;
        }

        try {
            setIsLoading(true);
            setDiscountError('');

            // Format the phone number to include the country code
            let formattedPhone = phone.trim();
            if (formattedPhone.startsWith("0")) {
                formattedPhone = "966" + formattedPhone.slice(1); // Add '966' and remove leading '0'
            }

            const payload = {
                discount_code: discountCode,
                user_name: name,
                user_email: email,
                user_phone: formattedPhone, // Use the formatted phone number
                reference_number: reference_number,
            };

            const discountValidationUrl = process.env.REACT_APP_VALIDATE_DISCOUNT;

            const response = await fetch(discountValidationUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(t("Payment.invalidDiscountCode"));
            }

            const result = await response.json();

            if (result.valid) {
                const discountPercentage = parseFloat(result.discountAmount || 0);
                const discountValue = (totalWithVat * discountPercentage) / 100;
                setDiscountAmount(discountValue);
                console.log("Discount validated successfully. Applied discount:", discountValue.toFixed(2));
                alert(t("Payment.discountAppliedSuccessfully"));
                setIsButtonVisible(false);
            } else {
                setDiscountAmount(0);
                setDiscountError(result.error || t("Payment.invalidDiscountCode"));
            }
        } catch (error) {
            console.error('Error validating discount code:', error);
            setDiscountError(t("Payment.errorProcessing"));
            setDiscountAmount(0);
        } finally {
            setIsLoading(false);
        }
    };

    const accommodationGrade = [
        { name: t("pdfuser.accommodation1"), showIcon: false },
        { name: t("pdfuser.accommodation2"), showIcon: false },
        { name: t("pdfuser.accommodation3"), showIcon: false },
        { name: t("pdfuser.accommodation4"), showIcon: false }
    ];
    const updateAccommodationGrade = (roomType) => {
        switch (roomType) {
            case 'fcr':
                accommodationGrade[0].showIcon = true;
                break;
            case 'rr':
                accommodationGrade[1].showIcon = true;
                break;
            case 'mr':
                accommodationGrade[2].showIcon = true;
                break;
            case 'pr':
                accommodationGrade[3].showIcon = true;
                break;
            default:
                break;
        }
    };
    updateAccommodationGrade(roomType);
    return (
        <div>
            <div className="invoice" id="invoice"  >
                <header className="invoice-header" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '2px solid #000'
                }}>

                    <div style={{ textAlign: 'left' }}>
                        <p className="headerpdfT">LahaLak medical platform</p>
                        <p className="headerpdf">Maroof no :214657</p>
                        <p className="headerpdf">T.M 1443017765</p>
                        <p className="headerpdf">C.R. 4030402711</p>
                        <p className="headerpdf">Tax reg 300095977700003 </p>

                    </div>
                    <img
src={loba2}
                        alt="Description of the image"
                        style={{ height: 'auto', width: '100px' }}
                    />
                    <div style={{ textAlign: 'right' }}>
                        <p className="headerpdfT">منصة لها ولك الطبية </p>
                        <p className="headerpdf">رقـــــــــم معروف 214657</p>
                        <p className="headerpdf">عـــــلامة تجارية 1443017765</p>
                        <p className="headerpdf"> السجل التجاري 4030402711</p>
                        <p className="headerpdf">الرقم الضريبي 300095977700003</p>
                    </div>
                </header>
                <div className="user-details" id="pdf-content" dir={getDirection(i18n.language)}>
                    <h1>{t("pdfuser.Quotation")}</h1>
                    <div className="infoBlock" dir={getDirection(i18n.language)}>

                        <div className="infoItem">
                            <p className="label">{t("pdfuser.Date")}</p>
                            <p className="value">{date}</p>
                        </div>
                    </div>
                    <br />
                    <div className=' aligne'>
                        <p className='redkhatb' style={{ color: 'red' }}>
                            {t("pdfuser.deadLine")}
                        </p>
                    </div>

                    <br />


                    <div className='BluedivHC' dir={getDirection(i18n.language)}>
                        <p className='titlecsollapseeWak'>{t("pdfuser.data")}</p>
                    </div>
                    <div className=" why" dir={getDirection(i18n.language)}>
                        {isDesktopOrLaptop ? (
                            <div className='mobiletl' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <div className='det_gr'>
                                    <div className='grid-cellule'>
                                        <p style={{ display: 'inline' }}>{t("pdfuser.name")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}>{name}</span>
                                    </div>
                                    <div className='grid-cellule'>
                                        <p style={{ display: 'inline' }}>{t("pdfuser.nationality")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}> {t(`Obstetrics_and_Gynecology.${nationality}`)}</span>
                                    </div>

                                    <div className='grid-cellule'>
                                        <p style={{ display: 'inline' }}>
                                            {t("pdfuser.pregnancyMonth")}:
                                        </p>
                                        <span className='sostext' style={{ display: 'inline' }}>
                                            {reference_number.split('|')[1] || "N/A"}
                                        </span>
                                    </div>

                                    <div className='grid-cellule'>
                                        <p style={{ display: 'inline' }}>{t("pdfuser.phone")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}>  {phone}
                                        </span>
                                    </div>

                                </div>
                            </div>
                        ) : (
                            <div className='desktoptl' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <div className='det_gr'>
                                    <div className='grid-cellule'>
                                        <p>{t("pdfuser.name")}:
                                            <span className='sostext'> {name}</span>
                                        </p>
                                    </div>
                                    <div className='grid-cellule'>
                                        <p >
                                            {t("pdfuser.nationality")}:
                                            <span className='sostext'>
                                                {t(`Obstetrics_and_Gynecology.${nationality}`)}
                                            </span>
                                        </p>
                                    </div>
                                    <div className='grid-cellule'>
                                        <p style={{ display: 'inline' }}>
                                            {t("pdfuser.pregnancyMonth")}:
                                        </p>
                                        <span className='sostext' style={{ display: 'inline' }}>
                                            {reference_number.split('|')[1] || "N/A"}
                                        </span>
                                    </div>

                                    <div className='grid-cellule'>
                                        <p >
                                            {t("pdfuser.phone")}:
                                            <span className='sostext'> {phone} </span>
                                        </p>


                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='BluedivHC' style={{ direction: getDirection() }}>
                        <p className='titlecsollapseeWak'>{t("pdfuser.dataService")}</p>
                    </div>
                    <div className="why" dir={getDirection(i18n.language)}>
                        {isDesktopOrLaptop ? (
                            <div className='mobiletl' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <div className='det_gr'>

                                    <div className='grid-cellule'>
                                        <p style={{ display: 'inline' }}>{t("pdfuser.hospitalName")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}>{hospital}</span>
                                    </div>



                                    <div className='grid-cellule'>
                                        <p style={{ display: 'inline' }}>
                                            {t("pdfuser.Branch")}:
                                        </p>
                                        <span className='sostext' style={{ display: 'inline' }}>
                                            {t(`Obstetrics_and_Gynecology.${Branch}`)}
                                        </span>
                                    </div>
                                    <div className='grid-cellule'>
                                        <p style={{ display: 'inline' }}>{t("pdfuser.ServiceName")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}>{Service}</span>
                                    </div>
                                </div>

                            </div>
                        )
                            : (
                                // mobile
                                <div
                                    className="financial-info-container"
                                    style={{
                                        '--direction': i18n.language === 'ar' ? 'rtl' : 'ltr',
                                    }}
                                >
                                    <div className='det_gr'>

                                        <div className='grid-cellule'>
                                            <p style={{ display: 'inline' }}>{t("pdfuser.hospitalName")}: </p>
                                            <span className='sostext' style={{ display: 'inline' }}>{hospital}</span>
                                        </div>



                                        <div className='grid-cellule'>
                                            <p style={{ display: 'inline' }}>
                                                {t("pdfuser.Branch")}:
                                            </p>
                                            <span className='sostext' style={{ display: 'inline' }}>
                                                {t(`Obstetrics_and_Gynecology.${Branch}`)}
                                            </span>
                                        </div>

                                        <div className='grid-cellule'>
                                            <p style={{ display: 'inline' }}>{t("pdfuser.ServiceName")}: </p>
                                            <span className='sostext' style={{ display: 'inline' }}>{Service}</span>
                                        </div>
                                    </div>
                                </div>

                            )}
                    </div>

                </div>

                <div className='khadametHamel' dir={getDirection(i18n.language)}>
                    {loading && <p className='whiteloading'>Loading...</p>}
                    {error && <p>{error}</p>}
                    {!loading && !error && (
                        <div className="fetched-data">
                            <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                {types.IncludedServices && types.IncludedServices.map((service, index) => (
                                    <div key={index}>
                                        <br />

                                        <div className='titlecollapseeB'>

                                            <div className='BluedivHC'>
                                                <p className='titlecsollapseeWak'>{service.title}</p>
                                            </div>
                                        </div>
                                        <br />
                                        <ul>
                                            {service.description.map((desc, idx) => (
                                                <li key={idx} className="totire">
                                                    <img src={icon2Img} alt="Icon" />
                                                    <span>{desc}</span>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>
                                ))}
                            </div>
                            <br />

                            <div className='BluedivHC'>
                                <p className='titlecsollapseeWak'>{t("Details.addedValues")}</p>
                            </div>

                            <br />
                            <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                {types.AddedValues && types.AddedValues.map((value, index) => (
                                    <div className='titlecollapseeB' key={index}>
                                        <ul>
                                            <li className="totire">
                                                <img src={icon2Img} alt="Icon" />
                                                <span>{value}</span>
                                            </li>

                                        </ul>
                                    </div>
                                ))}
                            </div>
                            <br />


                            <div className='BluedivHC'>
                                <p className='titlecsollapseeWak'>{t("Details.specialConditions")}</p>
                            </div>
                            <br />
                            <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <ul style={{ padding: 0 }}>
                                    {types.SpecialConditions && types.SpecialConditions.map((condition, index) => (
                                        <li className="safamak" key={index}>
                                            <span>{condition}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <br />


                            <div className='BluedivHC'>
                                <p className='titlecsollapseeWak'>{t("Details.generalConditions")}</p>
                            </div>
                            <br />
                            <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <ul style={{ padding: 0 }}>
                                    {types.GeneralConditions && types.GeneralConditions.map((condition, index) => (
                                        <li className="safamak" key={index}>
                                            <span>{condition}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    )}
                </div>
                <div className="why" dir={getDirection(i18n.language)}>
                    {isDesktopOrLaptop ? (
                        <div className='mobiletl' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>



                            <div
                                className="financial-data"
                                style={{
                                    '--direction': i18n.language === 'ar' ? 'rtl' : 'ltr'
                                }}
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>الخدمة</th>
                                            <th>السعر بدون الضريبة</th>
                                            <th>السعر مع الضريبة</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>{Service}</td>
                                            <td>{servicePrice.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                            <td>
                                                {nationality === "Saudi" ? (
                                                    <span>
                                                        {servicePrice.toFixed(2)} &nbsp; {t("Services.Pur6")}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {(servicePrice + vatServicePrice).toFixed(2)} &nbsp; {t("Services.Pur6")}
                                                    </span>
                                                )}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>الرسوم الإدارية</td>
                                            <td>{contractPrice.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                            <td> {vatContractPriceT.toFixed(2)}  &nbsp; {t("Services.Pur6")}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" className="total-label">المجموع</td>
                                            <td className="total-value">{totalWithVat.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>




                        </div>
                    )
                        : (

                            <div
                                className="financial-info-container"
                                style={{
                                    '--direction': i18n.language === 'ar' ? 'rtl' : 'ltr',
                                }}
                            >

                                <div className="financial-table-wrapper">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>الخدمة</th>
                                                <th>السعر بدون الضريبة</th>
                                                <th>السعر مع الضريبة</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>{Service}</td>
                                                <td>{servicePrice.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                                <td>
                                                    <span>
                                                        {nationality === "Saudi"
                                                            ? servicePrice.toFixed(2)
                                                            : (servicePrice + vatServicePrice).toFixed(2)}
                                                        &nbsp; {t("Services.Pur6")}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>الرسوم الإدارية</td>
                                                <td>{contractPrice.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                                <td>  {vatContractPriceT.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3" className="total-label">المجموع</td>
                                                <td className="total-value">{totalWithVat.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                </div>
                <div className="discount-code-section">
                    <h3>{t("Payment.discountCodeTitle")}</h3>
                    <div className="discount-code-input">
                        <input
                            type="text"
                            placeholder={t("Payment.enterDiscountCode")}
                            className={`discount-code-input ${i18n.language === "ar" ? "placeholder-align-rtl" : "placeholder-align"}`}
                            value={discountCode}
                            onChange={(e) => setDiscountCode(e.target.value)}
                        />
                        {isButtonVisible && ( // Conditionally render the button
                            <button
                                className="button-validate"
                                onClick={handleValidateDiscountCode}
                                disabled={isLoading}
                            >
                                {isLoading ? <span className="spinner"></span> : t("Payment.validateDiscountCode")}
                            </button>
                        )}
                    </div>

                </div>
                {discountError === "Invalid discount code" ? (
                    <p className="error-message"> {t("Payment.discountError")}</p>
                ) : (
                    discountError && <p className="error-message">{discountError}</p>
                )}
                {discountAmount > 0 && (
                    <p className="success-message">
                        {t("Payment.discountApplied")} {Number(discountAmount).toFixed(2)} {t("Services.Pur6")}
                    </p>
                )}
                <div>
                    {!agreed ? (
                        <div
                            className="agreement-section"
                            style={{
                                textAlign: 'center',
                                marginTop: '20px',
                                backgroundColor: '#f9f9f9',
                                padding: '15px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <label
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '12px',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#333',
                                }}
                            >

                                <span style={{ margin: 0 }}>
                                    {t("pdfuser.agreeToTerms")}{" "}
                                    <a
                                        href="https://lahalak.sa/Terms_and_conditions"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            color: '#0056b3',
                                            textDecoration: 'none',
                                            fontWeight: '600',
                                            cursor: 'pointer',
                                        }}
                                        onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
                                        onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
                                    >
                                        {t("pdfuser.termsAndConditions")}
                                    </a>
                                </span>



                                <span style={{ margin: 0 }}>
                                    {t("pdfuser.agreeToTermsT")}{" "}
                                    <a
                                        href="https://lahalak.sa/privacy_policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            color: '#0056b3',
                                            textDecoration: 'none',
                                            fontWeight: '600',
                                            cursor: 'pointer',
                                        }}
                                        onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
                                        onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
                                    >
                                        {t("pdfuser.privacy_policy")}
                                    </a>
                                </span>
                                <input
                                    type="checkbox"
                                    checked={agreed}
                                    onChange={() => {
                                        setAgreed(!agreed);
                                    }}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        cursor: 'pointer',
                                        accentColor: '#0056b3',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                    }}
                                />
                            </label>
                        </div>

                    ) : (
                        <button

                            className="button-downloadF buttonwhite"
                        >

                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='containerhkina'>
                                    <form onSubmit={handlePaymentNow} className="payment-form">

                                        <div className="form-group">
                                            <button type="submit" className="button-download blueNewTkhile">
                                                {t("Payment.payNow")}
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </span>
                        </button>
                    )}
                </div>















            </div>

        </div>
    );
}

export default Pdfuser;
