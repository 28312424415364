import React, { useState, useEffect, useRef } from 'react';
import './Women.css';
import icon1Img from './components/images/preg.png';
import icon2Img from './components/images/homeIcon.png';
import './components/Filters.css';
import Pill from "./Pill";
import icon5Img from './components/images/All_medicine.png';
import icon6Img from './components/images/Preventive_medicine.png';
import icon7Img from './components/images/Diagnostic_medicine.png';
import icon8Img from './components/images/Therapeutic_medicine.png';
import icon9Img from './components/images/Complementary_medicine.png';
import icon10Img from './components/images/Aesthetic_medicine.png';
import icon11Img from './components/images/watch.png';
import icon12Img from './components/images/Women_and_Obstetrics.png';
import icon13Img from './components/images/aftePre.png';
import icon16Img from './components/images/hospital.png';
import icon17Img from './components/images/Home_Services.png';
import icon18Img from './components/images/clinic.png';
import icon19Img from './components/images/Nursing_home.png';
import icon20Img from './components/images/Field_medicine.png';
import icon21Img from './components/images/Specialized_center.png';
import icon22Img from './components/images/Vaccinations.png';
import icon23Img from './components/images/Psychotherapy.png';
import icon24Img from './components/images/General_Surgery.png';
import icon25Img from './components/images/Binoculars.png';
import icon26Img from './components/images/Scouting.png';
import icon27Img from './components/images/Analysis.png';
import icon33Img from './components/images/Skin_diseases.png';
import icon34Img from './components/images/Tumors.png';
import icon36Img from './components/images/Women_and_Obstetrics.png';
import icon38Img from './components/images/beforepregen.png';
import icon39Img from './components/images/Reproductive_system_surgeries.png';
import icon41Img from './components/images/gynecological_infections.png';
import icon42Img from './components/images/Family_planning.png';
import icon43Img from './components/images/menopause.png';
import './WomenServices.css';
import { useTranslation } from 'react-i18next';
import icon1Imge from './components/images/icon12_2.png';
import icon2Imge from './components/images/calander.png';
import icon3Imge from './components/images/hamdanHospital.png';
import icon4Imge from './components/images/icon14.png';
import Obstetrics_and_GynecologyList from './Obstetrics_and_GynecologyList.js';
import { Link } from 'react-router-dom';

function WomenServices() {
    const { t, i18n } = useTranslation("global");
    const [activeFilters, setActiveFilters] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [serviceData, setServiceData] = useState([]);
    const [originalServiceData, setOriginalServiceData] = useState([]);
    const [isMobileFilterVisible, setIsMobileFilterVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [visibleItems, setVisibleItems] = useState(15);
    const bannerRef = useRef(null);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchAndFilterData = async () => {
            try {
                setIsLoading(true);
                const data = await fetchData();
                setOriginalServiceData(data);
                setServiceData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
                setServiceData([]);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAndFilterData();
    }, []);
    const acceptedTitles = [
        "طب-منزلي", "الطب-الوقائي", "الطب-التشخيصي", "الطب-العلاجي", "الطب-النفسي",
        "طب-النساء-والتوليد",
        "الوقاية-الغذائية", "التثقيف-الصحي",
        "الفحص-المبكر", "الأشعة-التشخيصية", "التحاليل-المخبرية",
        "التشخيص-الجيني", "العلاج-الدوائي",
        "العلاج-الكيميائي", "العلاج-الجراحي", "العلاج-الطبيعي", "العلاج-الوظيفي", "العلاج-النفسي",
        "العلاج-المناعي", "العلاج-الهرموني", "الجراحة-العامة",
        "الجراحة-التجميلية",
        "جراحة-الجهاز-الهضمي", "جراحة-المسالك-البولية",
        "أمراض-الدم", "أمراض-الغدد-الصماء", "الأمراض-المناعية",
        "اضطرابات-القلق", "الاكتئاب", "اضطرابات-الأكل", "اضطرابات-النوم",
        "الاضطرابات-ثنائية-القطب", "اضطرابات-الوسواس-القهري", "اضطراب-ما-بعد-الصدمة",
        "الاضطرابات-الشخصية", "طب-حديثي-الولادة",
        "الأمراض-الجينية", "طب-الأطفال-العام",
        "العناية-المركزة-للأطفال", "طب-الطوارئ-للأطفال", "طب-التوليد", "جمال-المراه", "طب-الخصوبة",
        "أمراض-الجهاز-التناسلي", "تنظيم-الأسرة", "طب-الأورام-النسائية", "جراحات-الجهاز-التناسلي",
        "طب-سن-اليأس", "علاج-العقم", "تشخيص-وعلاج-الالتهابات-النسائية",
        "الجراحات-التجميلية-النسائية", "العلاج-الطبيعي", "العلاج-الوظيفي",
        "إعادة-التأهيل-القلبي", "إعادة-تأهيل-ما-بعد-العمليات-الجراحية"
    ];

    const notAcceptedTitles = [
        "الطب-النووي",
        "طب-البيئي",
        "جراحة-الأعصاب",
        "جراحة-العظام",
        "طب-الأوبئة",
        "الصحة-المهنية",
        "الوقاية-من-الإدمان",
        "التطعيمات",
        "إعادة-تأهيل-السكتة-الدماغية",
        "العلاج-بالكهرباء",
        "إعادة-تأهيل-الأطراف-الصناعية",
        "تجميل-الأسنان",
        "جراحة-الفم",
        "إدمان-المخدرات-والكحول",
        "جراحة-الأوعية-الدموية",
        "أمراض-الذكورة",

    ];
    const getActiveFiltersText = () => {
        const filterEntries = Object.entries(activeFilters).filter(([_, value]) => value);

        if (filterEntries.length === 0) {
            return "لا توجد تصفية مفعلة حالياً.";
        }

        const filterText = filterEntries
            .map(([key, value]) => `${value}`)
            .join(", ");
        return `التصفية المفعلة: ${filterText}`;
    };
    const handleMobileFilterButtonClick = () => {
        setIsMobileFilterVisible(!isMobileFilterVisible);
        if (bannerRef.current) {
            const bannerPosition = bannerRef.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: bannerPosition - 80, // Adjust the top by 20px
                behavior: 'smooth',
            });
        }
    };

    const filterBySpecialiste2 = (services) => {
        return services.filter((service) => {
            try {
                const specialiste2Array = service.Specialiste2 ? JSON.parse(service.Specialiste2) : [];
                const serviceTitles = specialiste2Array.map(item => item.title);

                // Count matching titles from acceptedTitles
                const matchingCount = serviceTitles.filter(title => acceptedTitles.includes(title)).length;

                const hasNotAcceptedTitle = serviceTitles.some(title => notAcceptedTitles.includes(title));

                // Accept the service if it meets the criteria
                return matchingCount >= 2 && !hasNotAcceptedTitle;
            } catch (error) {
                return false; // Exclude services with invalid Specialiste2 data
            }
        });
    };

    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_URL7;
        try {
            const response = await fetch(apiUrl);
            if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
            const data = await response.json();


            // Filter data using the enhanced filter function
            const filteredData = filterBySpecialiste2(data);


            return filteredData;
        } catch (error) {
            return [];
        }
    };








    const sectionsData = [

        {
            title: "حدد التخصص",
            buttons: [
                {
                    label: "الأمراض الجلدية", icon:
                        <img src={icon33Img} className="category-icon" />
                },
                {
                    label: "الأورام", icon:
                        <img src={icon34Img} className="category-icon" />
                },

                {
                    label: "طب النساء", icon:
                        <img src={icon36Img} className="category-icon" />
                },

                {
                    label: "تنظيم الأسرة", icon:
                        <img src={icon42Img} className="category-icon" />
                },

                {
                    label: "طب سن اليأس", icon:
                        <img src={icon43Img} className="category-icon" />
                },
                {
                    label: "الجهاز التناسلي", icon:
                        <img src={icon39Img} className="category-icon" />
                },
                {
                    label: "الالتهابات النسائية", icon:
                        <img src={icon41Img} className="category-icon" />
                },

            ],
        }

    ];



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    const categoriesLocalFilter = [
        { label: t('renderServiceData.label1'), value: "", icon: icon5Img },
        { label: t('renderServiceData.label2'), value: "وقاية", icon: icon6Img },
        { label: t('renderServiceData.label3'), value: "تشخيص", icon: icon7Img },
        { label: t('renderServiceData.label4'), value: "علاج", icon: icon8Img },
        { label: t('renderServiceData.label5'), value: "رعاية", icon: icon9Img },
        { label: t('renderServiceData.label6'), value: "تجميل", icon: icon10Img },
    ];
    const iconData = [
        {
            img: icon4Imge,
            title: t("faq.ContactUtitletb33"),
            text1: t("faq.Contacttxttb331"),
            text2: t("faq.Contacttxttb332"),
        },
        {
            img: icon3Imge,
            title: t("faq.ContactUtitletb3"),
            text1: t("faq.Contacttxttb31"),
            text2: t("faq.Contacttxttb32"),
        },
        {
            img: icon2Imge,
            title: t("faq.ContactUtitletb2"),
            text1: t("faq.Contacttxttb21"),
            text2: t("faq.Contacttxttb22"),
        },
        {
            img: icon1Imge,
            title: t("faq.ContactUtitletb1"),
            text1: t("faq.Contacttxttb11"),
            text2: t("faq.Contacttxttb12"),
        },
    ];

    const renderServiceData = () => {
        if (isLoading) {
            return (
                <div className="centrPi">
                    <p className="centrPip">جاري تحميل البيانات...</p>
                </div>
            );
        }

        if (!serviceData.length) {
            return (
                <div className="centrPi">
                    <p className="centrPip">لا توجد بيانات متاحة حاليًا. يرجى المحاولة لاحقًا.</p>
                </div>
            );
        }

        const visibleServiceData = serviceData.slice(0, visibleItems);

        return (
            <div>
                {/* Active Filters Banner */}
                <div className="FiltreActivateBanner" ref={bannerRef}>
                    <p>{getActiveFiltersText()}</p>
                </div>

                {/* Desktop View */}
                <div className="desktop-view">
                    {visibleServiceData.map((service, index) => (
                        <div className="speed2desktopr" key={index}>
                            <Link to={`/DetailsAdd?id=${service.service_id}`}>
                                <img
                                    className="speed4desktop"
                                    src={service.image}
                                    alt="Service Visual Representation"
                                />
                                <p className="speed45desktop">{service.title}</p>
                            </Link>
                            <SummaryWithToggle summary={service.summary} />
                        </div>
                    ))}
                </div>

                {/* Mobile View */}
                <div className="mobile-view">
                    <div className="talej">
                        {visibleServiceData.map((service, index) => (
                            <div className="service-talej" key={index}>
                                <div className="rowrukaya">
                                    <h5 className="service-talejh5">
                                        <Link to={`/DetailsAdd?id=${service.service_id}`}>
                                            {service.title}
                                        </Link>

                                    </h5>

                                    <Link to={`/DetailsAdd?id=${service.service_id}`}>
                                        <img
                                            src={service.image}
                                            alt="Service Visual Representation"
                                            className="talej-image"
                                        />
                                    </Link>
                                </div>

                                {/* Second Row: Summary */}
                                < div className="summary-row" >
                                    <SummaryWithToggle summary={service.summary} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div >

                {/* Load More Button */}
                < br />
                <br />
                <br />
                <div className="centre">
                    {visibleItems < serviceData.length && (
                        <button className="moreB" onClick={() => setVisibleItems(visibleItems + 15)}>
                            المزيد
                        </button>
                    )}
                </div>
            </div >
        );
    };



    return (
        <div className='womenW'>
            <Obstetrics_and_GynecologyList />

            <div>
                <br />
                <br />
                <div className='centrPi'>
                    <h1 className="TitlrrContw">خطوات حجز باقة الولادة</h1>
                </div>
            </div>

            <div className="cardsContactUw">
                {iconData.map((data, index) => (
                    <div key={index} className="cardfiras">
                        <div>
                            <img
                                className='rijal'
                                src={data.img}
                                alt={data.title}
                                style={{ width: '40px', height: '40px' }}
                            />
                        </div>

                        <br />
                        <p className="textCCont">{data.title}</p>
                        <p className="texttCont">{data.text1}</p>
                        <p className="texttCont">{data.text2}</p>
                    </div>
                ))}
            </div>




            <div className="servicescontainer" style={{ direction: getDirection() }}>
                {isMobile && (
                    <button
                        className="filter-btn-women"
                        onClick={handleMobileFilterButtonClick}
                    >
                        <img src={icon1Img} className="Filter_Icon" />
                    </button>
                )}


                <div className="whiteamene">
                    <nav className="categories-nav">
                        <div className="categories-wrapper">
                            {categoriesLocalFilter.map((category, index) => (
                                <button
                                    key={index}
                                    className={`category-btn ${activeFilters.category === category.value ? "active" : ""}`}
                                    onClick={() => setActiveFilters({ ...activeFilters, category: category.value })}
                                >
                                    <img src={category.icon} alt={`${category.label} icon`} className="category-icon" />
                                    {category.label}
                                </button>
                            ))}

                        </div>
                    </nav>
                    <div className="main-layout">
                        {!isMobile && (
                            <div className="desktopV">
                                <RedSection
                                    sectionsData={sectionsData}
                                    originalServiceData={originalServiceData}
                                    setServiceData={setServiceData}
                                    activeFilters={activeFilters}
                                    setActiveFilters={setActiveFilters}
                                    setIsMobileFilterVisible={setIsMobileFilterVisible}
                                    t={t} // Pass `t` here

                                />
                            </div>
                        )}
                        <div className={`mobile-filter-panel mobileV ${isMobileFilterVisible ? "visible" : ""}`}>
                            <RedSection
                                sectionsData={sectionsData}
                                originalServiceData={originalServiceData}
                                setServiceData={setServiceData}
                                activeFilters={activeFilters}
                                setActiveFilters={setActiveFilters}
                                setIsMobileFilterVisible={setIsMobileFilterVisible}
                                t={t}

                            />
                            <button className="close-filter-btn" onClick={() => setIsMobileFilterVisible(false)}>
                                اغلاق
                            </button>
                        </div>
                        <div className="blue-section">
                            <div className="Contdeps">
                                <h1 className="TitlrrContw">
                                    {t("services.Children")} ({serviceData.length})
                                </h1>                                <div className="speed1">
                                    {isLoading ? <Pill /> : renderServiceData()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default WomenServices;



































const RedSection = ({
    sectionsData,
    originalServiceData,
    setServiceData,
    activeFilters,
    setActiveFilters,
    setIsMobileFilterVisible,
    t
}) => {

    const [visibleButtons, setVisibleButtons] = useState(4);
    const [visibleSpecialistButtons, setVisibleSpecialistButtons] = useState(4);
    const [visibleProcedureButtons, setVisibleProcedureButtons] = useState(4);
    const [visibleTimePeriodButtons, setVisibleTimePeriodButtons] = useState(4);



    const handleLocationSelect = (location) => {
        setActiveFilters((prevFilters) => {
            const isActive = prevFilters.serviceLocationSection === location;
            return {
                ...prevFilters,
                serviceLocationSection: isActive ? null : location,
            };
        });
        setIsMobileFilterVisible(false);
    };
    const SelectSpecialist = {
        title: "حدد التخصص",
        key: "SelectSpecialist", // Unique key for this section
        buttons: [
            {
                label: "الأمراض الجلدية",
                key: "الأمراض الجلدية", // Unique key for this button
                icon: <img src={icon33Img} className="category-icon" />,
            },
            {
                label: "الأورام",
                key: "الأورام",
                icon: <img src={icon34Img} className="category-icon" />,
            },
            {
                label: "طب النساء",
                key: "طب النساء",
                icon: <img src={icon36Img} className="category-icon" />,
            },
            {
                label: "تنظيم الأسرة",
                key: "تنظيم الأسرة",
                icon: <img src={icon42Img} className="category-icon" />,
            },
            {
                label: "طب سن اليأس",
                key: "طب سن اليأس",
                icon: <img src={icon43Img} className="category-icon" />,
            },
            {
                label: "الجهاز التناسلي",
                key: "الجهاز التناسلي",
                icon: <img src={icon39Img} className="category-icon" />,
            },
            {
                label: "الالتهابات النسائية",
                key: "الالتهابات النسائية",
                icon: <img src={icon41Img} className="category-icon" />,
            },
        ],
    };

    const timePeriod = {
        title: "حدد المرحلة الزمنية",
        key: "timePeriod",
        buttons: [
            {
                label: "خدمات قبل الحمل",
                key: "قبل الحمل",
                icon: <img src={icon38Img} className="category-icon" />,
            },
            {
                label: "خدمات أثناء الحمل",
                key: "أثناء الحمل",
                icon: <img src={icon12Img} className="category-icon" />,
            },
            {
                label: "خدمات بعد الولادة",
                key: "بعد الولادة",
                icon: <img src={icon13Img} className="category-icon" />,
            },
            {
                label: "متابعة الحمل",
                key: "متابعة الحمل",
                icon: <img src={icon11Img} className="category-icon" />,
            },
        ],
    };

    // const serviceLocationSection = {
    //     title: "حدد نوع مزود الخدمة",
    //     key: "serviceLocationSection",
    //     buttons: [
    //         {
    //             label: "المستشفى", key: "في المستشفى", icon:

    //                 < img src={icon16Img} className="category-icon" />
    //         },
    //         {
    //             label: "المنزل", key: "في المنزل", icon:
    //                 < img src={icon17Img} className="category-icon" />
    //         },
    //         {
    //             label: "المستوصف", key: "المستوصف", icon:
    //                 < img src={icon18Img} className="category-icon" />
    //         },
    //         {
    //             label: "دار رعاية", key: "دار رعاية", icon:
    //                 < img src={icon19Img} className="category-icon" />
    //         },
    //         {
    //             label: "الطب الميداني", key: "الطب الميداني", icon:
    //                 < img src={icon20Img} className="category-icon" />
    //         },
    //         {
    //             label: "مركز تخصصي", key: "مركز تخصصي", icon:
    //                 < img src={icon21Img} className="category-icon" />
    //         },
    //     ],
    // };
    const procedureSection = {
        title: t("renderServiceData.procedureTitle"),
        key: "procedureSection",
        buttons: [
            {
                label: t("renderServiceData.label11"), key: "كشف", icon:
                    <img src={icon26Img} className="category-icon" />
            },
            {
                label: "الأشعة", key: "الأشعة", icon:
                    <img src={icon22Img} className="category-icon" />
            },
            {
                label: t("renderServiceData.label8"), key: "العلاج النفسي", icon:
                    <img src={icon23Img} className="category-icon" />
            },
            {
                label: t("renderServiceData.label9"), key: "جراحة عامة", icon:
                    <img src={icon24Img} className="category-icon" />
            },
            {
                label: t("renderServiceData.label10"), key: "مناظير", icon:
                    <img src={icon25Img} className="category-icon" />
            },

            {
                label: t("renderServiceData.label12"), key: "تحاليل", icon:
                    <img src={icon27Img} className="category-icon" />
            },
        ],
    };




    useEffect(() => {
        const filteredServices = originalServiceData.filter((service) => {
            if (!service.filters) return false;

            try {
                const serviceFilters = JSON.parse(service.filters); // Parse filters from service

                return Object.entries(activeFilters).every(([sectionKey, filterKey]) => {
                    if (!filterKey) return true;
                    return serviceFilters[sectionKey]?.includes(filterKey);
                });
            } catch (error) {
                return false;
            }
        });

        setServiceData(filteredServices);
    }, [activeFilters, originalServiceData]); // Depend on activeFilters and originalServiceData





    const handleFilterClick = (sectionKey, filterKey) => {
        setActiveFilters((prevFilters) => ({
            ...prevFilters,
            [sectionKey]: prevFilters[sectionKey] === filterKey ? null : filterKey,
        }));
        setIsMobileFilterVisible(false);

    };
    useEffect(() => {
        const filteredServices = originalServiceData.filter((service) => {
            if (!service.filters && !service.Specialiste2 && !service.sections && !service.included_services && !service.description)
                return false;

            try {
                const parsedFilters = service.filters ? JSON.parse(service.filters) : {};
                const parsedSpecialiste2 = service.Specialiste2 ? JSON.parse(service.Specialiste2) : [];
                const parsedSections = service.sections ? JSON.parse(service.sections) : [];
                const titles = parsedSpecialiste2.map((item) => item.title);
                const includedServices = service.included_services || "";
                const description = service.description || "";

                return Object.entries(activeFilters).every(([sectionKey, filterKey]) => {
                    if (!filterKey) return true;

                    switch (sectionKey) {
                        case "category":
                            return service.filters?.includes(filterKey);

                        case "serviceLocationSection":
                            return parsedFilters.service_location?.includes(filterKey);

                        case "specialitySection":
                            if (filterKey === "الأمراض الجلدية") {
                                return titles.includes("التجميل-الجراحي");
                            } else if (filterKey === "التحاليل") {
                                return titles.includes("التحاليل-المخبرية");

                            } else if (filterKey === "الأورام") {
                                return (
                                    titles.includes("جراحة-الأورام") ||
                                    titles.includes("العلاج-الإشعاعي") ||
                                    titles.includes("العلاج-الكيميائي") ||
                                    titles.includes("طب-الأورام-النسائية")
                                );
                            } else if (filterKey === "طب النساء") {
                                return (
                                    titles.includes("طب-التوليد") ||
                                    titles.includes("طب-الخصوبة") ||
                                    titles.includes("تشخيص-وعلاج-الالتهابات-النسائية") ||
                                    titles.includes("طب-الأورام-النسائية")
                                );
                            } else if (filterKey === "تنظيم الأسرة") {
                                return titles.includes("تنظيم-الأسرة");

                            } else if (filterKey === "طب سن اليأس") {
                                return titles.includes("طب-سن-اليأس");
                            } else if (filterKey === "الجهاز التناسلي") {
                                return titles.includes("جراحات-الجهاز-التناسلي");
                            } else if (filterKey === "الالتهابات النسائية") {
                                return titles.includes("تشخيص-وعلاج-الالتهابات-النسائية");
                            }
                            return false;


                        case "procedureSection": {
                            const keywords = {
                                الأشعة: ["الأشعة"],
                                "العلاج النفسي": ["علاج جماعي", "علاج نفسي", "النطق"],
                                "جراحة عامة": ["جراحة"],
                                مناظير: ["منظار"],
                                كشف: [" استشاري ", " اخصائي ", " أخصائية جلدية "],
                                تحاليل: ["تحليل"],
                            };
                            return parsedSections.some((section) =>
                                keywords[filterKey]?.some((word) =>
                                    section.title.includes(word) ||
                                    section.content.some((text) => text.includes(word))
                                )
                            );
                        }


                        case "SelectSpecialist": {
                            const specialistKeywords = {
                                "الأمراض الجلدية": [
                                    "أكزيما",
                                    "صدفية",
                                    "وحمات",
                                    "تساقط الشعر",
                                ],
                                "الأورام": [
                                    "الأورام",
                                    "الكيميائي",
                                ],
                                "طب النساء": [
                                    "متابعة الحمل",
                                    "تنظيم الدورة الشهرية",
                                    "علاج تكيس المبايض",
                                    "استشارات النساء",
                                ],
                                "تنظيم الأسرة": [
                                    "تنظيم النسل",
                                    "وسائل منع الحمل",
                                    "الفحص الدوري",
                                ],
                                "طب سن اليأس": [
                                    "علاج أعراض سن اليأس",
                                    "هشاشة العظام",
                                    "اضطرابات الهرمونات",
                                ],
                                "الجهاز التناسلي": [
                                    "علاج التهابات الجهاز التناسلي",
                                    "جراحات الجهاز التناسلي",
                                ],
                                "الالتهابات النسائية": [
                                    "تشخيص الالتهابات النسائية",
                                    "علاج الالتهابات النسائية",
                                ],
                            };

                            const keywords = specialistKeywords[filterKey] || [];
                            const includedServicesMatch = keywords.some((keyword) => includedServices.includes(keyword));
                            const descriptionMatch = keywords.some((keyword) => description.includes(keyword));
                            const sectionsMatch = parsedSections.some((section) =>
                                keywords.some((keyword) => section.title.includes(keyword) || section.content?.includes(keyword))
                            );
                            return includedServicesMatch || descriptionMatch || sectionsMatch;
                        }



                        case "timePeriod": {
                            const timePeriodKeywords = {
                                "قبل الحمل": ["الخصوبه",
                                    "كشف الحمل المبكر",
                                    "(Ferritin in Serum)",
                                    "(ACL) IgM",
                                    "cbc",
                                    "المبيض", "(Glucose in Plasma (Random))",
                                    "الاجهاض",
                                    "Allergens Specific IgE Screening",
                                    "(AST)",
                                    "تحليل برولاكتين (Prolactin)",
                                    "hyroid Peroxidase Abs",
                                    "(Insulin Level)"],
                                "أثناء الحمل": ["أثناء الحمل", "الحمل", "Alkaline Phosphatase"],
                                "بعد الولادة": ["بعد الولادة", "خدمات بعد الولادة"],
                                "متابعة الحمل": [
                                    "متابعة الحمل",
                                    "متابعة حمل",
                                    "متابعة الحمل والولادة",
                                    "خدمات متابعة الحمل", "كشف أخصائي النساء"],
                            };

                            const keywords = timePeriodKeywords[filterKey] || [];
                            const includedServicesMatch = keywords.some((keyword) => includedServices.includes(keyword));
                            const descriptionMatch = keywords.some((keyword) => description.includes(keyword));
                            const sectionsMatch = parsedSections.some((section) =>
                                keywords.some((keyword) => section.title.includes(keyword) || section.content?.includes(keyword))
                            );
                            return includedServicesMatch || descriptionMatch || sectionsMatch;
                        }
                        default:
                            return false;
                    }
                });
            } catch (error) {
                return false;
            }
        });

        setServiceData(filteredServices);
    }, [activeFilters, originalServiceData]);




    return (
        <div >
            <div className="red-title-section">
                <div className="titpart centre">
                    <p>{t("renderServiceData.label18")}</p>
                </div>
                <div className="red-buttons-row">
                    <button
                        className={`category-button ${activeFilters.serviceLocationSection === "في المنزل" ? "active" : ""
                            }`}
                        onClick={() => handleLocationSelect("في المنزل")}
                    >
                        <span className="button-icon">
                            <img src={icon2Img} className="category-icon" alt="Home Icon" />
                        </span>
                        <p className="category-text">
                            {t("renderServiceData.label17")}
                        </p>
                    </button>

                    <button
                        className={`category-button ${activeFilters.specialitySection === "التحاليل" ? "active" : ""
                            }`}
                        onClick={() => handleFilterClick("specialitySection", "التحاليل")}
                    >
                        <span className="button-icon">
                            <img src={icon27Img} className="category-icon" alt="Analysis Icon" />
                        </span>
                        <p className="category-text">التحاليل</p>
                    </button>
                </div>
            </div>

            <div className="red-title-section">
                <div className="titpart centre">
                    <p>{timePeriod.title}</p>
                </div>
                <div className="red-buttons-row">
                    {timePeriod.buttons.slice(0, visibleTimePeriodButtons).map((button, i) => (
                        <button
                            key={i}
                            className={`red-button ${activeFilters.timePeriod === button.key ? "active" : ""
                                }`}
                            onClick={() => handleFilterClick(timePeriod.key, button.key)}
                        >
                            <span className="button-icon">{button.icon}</span>
                            <p className="category-text">{button.label}</p>
                        </button>
                    ))}
                </div>
                {timePeriod.buttons.length > visibleTimePeriodButtons && (
                    <button
                        className="more-button"
                        onClick={() => setVisibleTimePeriodButtons(visibleTimePeriodButtons + 4)}
                    >
                        عرض المزيد
                    </button>
                )}
                {visibleTimePeriodButtons > 4 && (
                    <button
                        className="more-button"
                        onClick={() => setVisibleTimePeriodButtons(4)}
                    >
                        عرض أقل
                    </button>
                )}
            </div>

            <div className="red-title-section">
                <div className="titpart centre">
                    <p>{procedureSection.title}</p>
                </div>
                <div className="red-buttons-row">
                    {procedureSection.buttons.slice(0, visibleProcedureButtons).map((button, i) => (
                        <button
                            key={i}
                            className={`red-button ${activeFilters.procedureSection === button.key ? "active" : ""
                                }`}
                            onClick={() => handleFilterClick(procedureSection.key, button.key)}
                        >
                            <span className="button-icon">{button.icon}</span>
                            <p className="category-text">{button.label}</p>
                        </button>
                    ))}
                </div>
                {procedureSection.buttons.length > visibleProcedureButtons && (
                    <button
                        className="more-button"
                        onClick={() => setVisibleProcedureButtons(visibleProcedureButtons + 4)}
                    >
                        عرض المزيد
                    </button>
                )}
                {visibleProcedureButtons > 4 && (
                    <button
                        className="more-button"
                        onClick={() => setVisibleProcedureButtons(4)}
                    >
                        عرض أقل
                    </button>
                )}
            </div>
            <div className="red-title-section">
                <div className="titpart centre">
                    <p>{SelectSpecialist.title}</p>
                </div>
                <div className="red-buttons-row">
                    {SelectSpecialist.buttons.slice(0, visibleSpecialistButtons).map((button, i) => (
                        <button
                            key={i}
                            className={`red-button ${activeFilters.SelectSpecialist === button.key ? "active" : ""
                                }`}
                            onClick={() => handleFilterClick(SelectSpecialist.key, button.key)}
                        >
                            <span className="button-icon">{button.icon}</span>
                            <p className="category-text">{button.label}</p>
                        </button>
                    ))}
                </div>
                {SelectSpecialist.buttons.length > visibleSpecialistButtons && (
                    <button
                        className="more-button"
                        onClick={() => setVisibleSpecialistButtons(visibleSpecialistButtons + 4)}
                    >
                        عرض المزيد
                    </button>
                )}
                {visibleSpecialistButtons > 4 && (
                    <button
                        className="more-button"
                        onClick={() => setVisibleSpecialistButtons(4)}
                    >
                        عرض أقل
                    </button>
                )}
            </div>
            {/* <div className="red-title-section">
                <div className="titpart centre">
                    <p>{serviceLocationSection.title}</p>
                </div>
                <div className="red-buttons-row">
                    {serviceLocationSection.buttons.slice(0, visibleButtons).map((button, i) => (
                        <button
                            key={i}
                            className={`red-button ${activeFilters.serviceLocationSection === button.key ? "active" : ""
                                }`}
                            onClick={() => handleFilterClick(serviceLocationSection.key, button.key)}
                        >
                            <span className="button-icon">{button.icon}</span>
                            <p className="category-text">{button.label}</p>
                        </button>
                    ))}
                </div>
                {serviceLocationSection.buttons.length > visibleButtons && (
                    <button
                        className="more-button"
                        onClick={() => setVisibleButtons(visibleButtons + 4)}
                    >
                        عرض المزيد
                    </button>
                )}
                {visibleButtons > 4 && (
                    <button
                        className="more-button"
                        onClick={() => setVisibleButtons(4)}
                    >
                        عرض أقل
                    </button>
                )}
            </div> */}
        </div>
    );

};

function SummaryWithToggle({ summary }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <p className="speed53desktop">
            {isExpanded ? summary : `${summary.slice(0, 180)}...`} {/* Truncated or full summary */}
            {summary.length > 40 && (
                <span
                    className="showMoreS"
                    onClick={handleToggle}
                    style={{ cursor: "pointer", color: "#5271ff" }}
                >
                    {isExpanded ? " عرض أقل" : "المزيد"}
                </span>
            )}
        </p>
    );
}