import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkUserLoggedIn = async () => {
    try {
      const checkUserUrl = process.env.REACT_APP_CHECK_USER_URL;
  
      const response = await fetch(checkUserUrl, {
        method: "GET",
        credentials: "include",
      });
  
      const data = await response.json();
  
      if (data.loggedIn) {
        setUser(data.user);
      } else {
        setUser(null);
      }
    } catch (error) {
      setUser(null);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const value = {
    user,
    loading,
  };
 
  return (
    <>
      {loading ? (
        <p className="whiteloading">Please wait for a moment...</p>
      ) : (
        <AuthContext.Provider value={value}>
          {children}
        </AuthContext.Provider>
      )}
    </>
  );
};
